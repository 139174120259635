import { createContext, useContext, useState } from 'react';

type OpenMenuContextValue = {
  isOpen: boolean;
  toggle: (isOpen: boolean) => void;
};

const OpenMenuContext = createContext<OpenMenuContextValue>({
  isOpen: false,
  toggle: () => {},
});

type OpenMenuProviderProps = {
  children: React.ReactNode;
};

export function OpenMenuProvider({ children }: OpenMenuProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const value = {
    isOpen,
    toggle,
  };

  return <OpenMenuContext.Provider value={value}>{children}</OpenMenuContext.Provider>;
}

export const useOpenMenu = () => useContext(OpenMenuContext);
