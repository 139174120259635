import I18n from 'i18n-js';

export default function FallbackCard() {
  return (
    <div className="fallbackCard">
      <h2>{I18n.t('solar.noFleets.title')}</h2>
      <p>{I18n.t('solar.noFleets.text')}</p>
    </div>
  );
}
