import { useState, useCallback, lazy, Suspense, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import I18n from './i18n';
import { ModalProvider } from './context/modalContext';
import { AuthProvider } from './context/authContext';
import { OpenMenuProvider } from './context/openMenuContext';
import { ROLE } from './constants/roles';
import './styles/index.scss';
import { PrivateRoute } from './privateRoute';
import { VehicleOperatingModesProvider } from './context/vehicleOperatingModesContext';
import Loader from './components/common/loader';
import { Language } from './i18n';

const Login = lazy(() => import('./pages/login'));
const VehicleDetails = lazy(() => import('./pages/vehicleDetails'));
const Documents = lazy(() => import('./pages/documents'));
const Admin = lazy(() => import('./pages/admin'));
const Fleets = lazy(() => import('./components/fleets'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const MyFleetPage = lazy(() => import('./pages/myFleetPage'));
const Settings = lazy(() => import('./pages/settings'));
const Faqs = lazy(() => import('./pages/faqs'));
const LocaleSwitcher = lazy(() => import('./components/common/localeSwitcher'));

function Index() {
  const [locale, _setLocale] = useState<Language>(I18n.currentLocale() as Language);

  const setLocale = useCallback((l: Language) => {
    I18n.setLocale(l || I18n.currentLocale());
    _setLocale(l || I18n.currentLocale());
  }, []);

  return (
    <>
      <HashRouter>
        <ModalProvider>
          <AuthProvider>
            <OpenMenuProvider>
              <LocaleSwitcher value={locale} onLocaleSelected={setLocale} />
              <Routes>
                <Route path="login" element={<Login />} />
                <Route
                  path=":fleet"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                      <VehicleOperatingModesProvider>
                        <Suspense fallback={<Loader />}>
                          <Dashboard />
                        </Suspense>
                      </VehicleOperatingModesProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/fleets"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_FLEETS]}>
                      <Suspense fallback={<Loader />}>
                        <Fleets />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/fleet"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_MY_FLEET]}>
                      <VehicleOperatingModesProvider>
                        <Suspense fallback={<Loader />}>
                          <MyFleetPage />
                        </Suspense>
                      </VehicleOperatingModesProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/details/:vehicleId"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_VEHICLE_DETAILS]}>
                      <VehicleOperatingModesProvider>
                        <Suspense fallback={<Loader />}>
                          <VehicleDetails />
                        </Suspense>
                      </VehicleOperatingModesProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/settings"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_VEHICLES, ROLE.CAN_VIEW_USERS]}>
                      <Suspense fallback={<Loader />}>
                        <Settings />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/admin"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_FLEETS, ROLE.CAN_VIEW_USERS]}>
                      <Suspense fallback={<Loader />}>
                        <Admin />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/reports"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                      <Suspense fallback={<Loader />}>
                        <Documents documentType="reports" />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/docs"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                      <Suspense fallback={<Loader />}>
                        <Documents documentType="docs" />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/faqs"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_FAQS]}>
                      <Suspense fallback={<Loader />}>
                        <Faqs />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route path="/" element={<Navigate replace to={'/*'} />} />
              </Routes>
            </OpenMenuProvider>
          </AuthProvider>
        </ModalProvider>
      </HashRouter>
    </>
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <Index />
    </Suspense>
  </StrictMode>,
);
