import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/authContext';
import { doFetch } from '../fetchers/common';
import { useOperatingModes } from '../context/fleetContext';
import { OperatingMode, VehicleOperatingMode } from '@solar-data/schemas/lib/owned-by/solar';
import dayjs from 'dayjs';

// Interface representing the vehicle operating mode with additional UI-specific properties.
export interface UiVehicleOperatingMode extends VehicleOperatingMode {
  mode?: OperatingMode;
}

export function useVehicleOperatingModes(fleetSlug: string, vehicleId: number) {
  const [vehicleOperatingModes, setVehicleOperatingModes] = useState(undefined as UiVehicleOperatingMode[] | undefined);
  const [error, setError] = useState(undefined as string | undefined);
  const { operatingModes } = useOperatingModes();

  const { logout, getHeaders } = useAuth();

  const reload = useCallback(
    async () =>
      doFetch<VehicleOperatingMode[]>(
        `api/fleets/${fleetSlug}/vehicles/${vehicleId}/operatingModes`,
        { headers: await getHeaders() },
        setError,
        logout,
        (voms: any) =>
          setVehicleOperatingModes(
            voms?.map((vom: any) => {
              return { ...vom, mode: operatingModes.find((om) => om.id === vom.id) };
            }),
          ),
      ),
    [getHeaders, logout, fleetSlug, vehicleId, operatingModes],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  const setMode = useCallback(
    async (day: string, id: number, reason: string) => {
      const dayDate = dayjs(day).format('YYYY-MM-DD');
      await doFetch(
        `api/fleets/${fleetSlug}/vehicles/${vehicleId}/operatingModes/${dayDate}`,
        {
          method: 'PUT',
          headers: {
            ...(await getHeaders()),
            'Content-Type': 'application/json',
          },
          data: { id, reason },
        },
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, reload, fleetSlug, vehicleId],
  );

  return { setMode, vehicleOperatingModes, error };
}
