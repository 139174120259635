import { useEffect } from 'react';
import { useFleets, useVehicles } from '../../context/fleetContext';
import I18n from 'i18n-js';
import { useOpenMenu } from '../../context/openMenuContext';
import { ROLE } from '../../constants/roles';
import Logout from './logout';
import MobileMenuBar from './mobileMenuBar';
import ClientComponent from './clientComponent';
import MenuContainer from './menuContainer';

export type Page = { title: string; href: string; match?: string; id: string; hidden?: boolean; disabled?: boolean };

export default function Menu() {
  const { fleets, currentFleet } = useFleets();
  const { vehicles } = useVehicles();
  const { isOpen } = useOpenMenu();

  // Check if the current user is a viewer, fleet admin or global admin
  const isViewer = fleets.find((f) => f.slug === currentFleet)?.role === ROLE.VIEWER;
  const isGlobalAdmin = fleets.some((fleet) => fleet.slug === 'admin' && fleet.role === 'admin');
  const isAdminFleetSelected = currentFleet === 'admin';

  useEffect(() => {
    document.body.className = isOpen ? 'menuOpen' : 'menuClosed';
  }, [isOpen]);

  const pages: Page[] = [
    {
      title: I18n.t('solar.dashboard.title'),
      href: `#/${currentFleet}`,
      id: 'menu__dashboard',
    },
    {
      title: I18n.t('solar.myfleet.title'),
      href: `#/${currentFleet}/fleet`,
      disabled: isAdminFleetSelected,
      id: 'menu__myfleet',
    },
    {
      title: I18n.t('solar.vehicleDetails.title'),
      href: `#/${currentFleet}/details/${vehicles[0]?.id}`,
      match: `#/${currentFleet}/details/.*$`,
      disabled: isAdminFleetSelected,
      id: 'menu__vehicle_details',
    },
    {
      title: I18n.t('solar.documents.reports.menuTitle'),
      href: `#/${currentFleet}/reports`,
      disabled: isAdminFleetSelected,
      id: 'menu__reports',
    },
    {
      title: I18n.t('solar.documents.docs.title'),
      href: `#/${currentFleet}/docs`,
      id: 'menu__docs',
    },
    {
      title: I18n.t('solar.faqs.title'),
      href: `#/${currentFleet}/faqs`,
      id: 'menu__faqs',
    },
    {
      title: I18n.t('solar.settings.title'),
      href: `#/${currentFleet}/settings`,
      disabled: isAdminFleetSelected,
      hidden: isViewer,
      id: 'menu__settings',
    },
    {
      title: I18n.t('solar.admin.title'),
      href: `#/admin/admin`,
      hidden: !isGlobalAdmin,
      id: 'menu__admin',
    },
  ];

  return (
    <>
      <MobileMenuBar pages={pages} />
      <MenuContainer pages={pages}>
        <ClientComponent />
        <Logout />
      </MenuContainer>
    </>
  );
}
