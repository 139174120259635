type ModalProps = { children: React.ReactNode; contentClassName?: string };

export default function Modal({ children, contentClassName }: ModalProps) {
  return (
    <>
      <div className="modal background" />
      <div className={`modal dialog ${contentClassName || ''}`}>{children}</div>
    </>
  );
}
