import I18n from 'i18n-js';
import { useOpenMenu } from '../../context/openMenuContext';
import { Page } from './menu';
import Logo from './logo';
import HamburgerIcon from './hamburgerIcon';

function MobileMenuBar({ pages }: { pages: Page[] }) {
  const { isOpen, toggle } = useOpenMenu();
  return (
    <div className={`menuBar ${isOpen ? 'open' : 'closed'}`}>
      <button onClick={() => toggle(!isOpen)}>
        <HamburgerIcon />
        <p className={`current ${isOpen && 'open'}`}>
          {isOpen
            ? 'Close'
            : pages.find((page) =>
                page.match ? window.location.href.match(page.match) : window.location.href.endsWith(page.href),
              )?.title ?? I18n.t('solar.dashboard.title')}
        </p>
      </button>
      <Logo link={'sono_small.png'} name={'sono-small'} />
    </div>
  );
}

export default MobileMenuBar;
