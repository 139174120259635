import I18n from 'i18n-js';
import { memo } from 'react';
import { useFleets } from '../../context/fleetContext';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const { fleets, currentFleet } = useFleets();
  const { logout, currentUserName } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login', { replace: true });
  };
  return (
    <div className="logout">
      {fleets.length === 1 && <p className="companyName">{fleets.find((f) => f.slug === currentFleet)?.name}</p>}
      {currentUserName && <p className="userName">{currentUserName}</p>}
      <a href="#/logout" onClick={handleLogout} id="logout__link">
        {I18n.t('solar.menu.logout')}
      </a>
    </div>
  );
}

export default memo(Logout);
