import { useOpenMenu } from '../../context/openMenuContext';

type MenuLinkProps = React.PropsWithChildren<{
  href: string;
  match?: string;
  disabled?: boolean;
  id: string;
}>;

export default function MenuLink({ href, match, children, disabled, id }: MenuLinkProps) {
  const { isOpen, toggle } = useOpenMenu();
  const isActive = match ? window.location.href.match(match) : window.location.href.endsWith(href);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    toggle(!isOpen);
  };

  return (
    <li className={`${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
      <a href={href} onClick={handleClick} id={id}>
        {children}
      </a>
    </li>
  );
}
